import Code from './code.svg';
import Touch from './touch.svg';
import Sleep from './sleep.svg';
import ChatColor from './chat-color.svg';
import Slack from './slack.svg';
import Email from './email.svg';
import LongPress from './long-press.svg';
import VolumeUp from './volume-up.svg';
import VolumeDown from './volume-down.svg';
import Swipe from './swipe.svg';
import Switcher from './switcher.svg';

export default {
  Code,
  Touch,
  Sleep,
  ChatColor,
  Slack,
  Email,
  LongPress,
  VolumeUp,
  VolumeDown,
  Swipe,
  Switcher,
};
